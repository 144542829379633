
/* SideBar */
.sideNav
{
    padding: 7px 45px 0px 19px;;
    flex:var(--sidebar-flex);
    background: linear-gradient(180deg, rgb(103 105 238) 50%, rgba(132,124,252,1) 80%);
}

nav ul
{
    font-size:var(--fontsize-nav) ;
    text-align: center;
    list-style-type: none;
    text-decoration: none;
    color: white !important;
}

nav ul li
{
    margin:40px;
}

nav a:hover{
    font-weight: bolder;
}

.mobileNav{
    display: none;
}

.deskNav{
    position: fixed;
}

@media only screen and (max-width: 600px) {
    .deskNav{
      display: none;
    }
    .mobileNav{
        display: block;
    }
    .mobileNav .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgb(103 105 238) 50%, rgba(132,124,252,1) 80%);
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 30px;
      }
      
    .mobileNav .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 18px;
        color:white;
        display: block;
        transition: 0.3s;
      }
      
    .mobileNav .sidenav a:hover {
        color: #f1f1f1;
      }
      
    .mobileNav .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
      }

    .mobileNav span{
        font-size: 20px;
        cursor: pointer;
        color:wheat;
    }

    .mobileNav li{
        list-style-type: none;
    }
}

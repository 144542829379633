
.home {
    margin: 0;
    min-height: 100vh;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .typingeffect {
    font-size: var(--typing-font);
    margin-bottom: 15px;
    margin-top: 20px;
    font-family: "Courier New", Courier, monospace;
  }

.home .container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

.smallPhoto{
    border-radius: 20%;
}

@media only screen and (max-width: 600px) {
    .typingeffect {
        font-size: 18px;
        margin-bottom: 15px;
        margin-top: 20px;
        font-family: "Courier New", Courier, monospace;
    }
    .home .large{
        display: none;
    }
    .home .smallPhoto{
        display:block;
        border-radius: 35%;
        width: 200px;
        margin: 30px;
        filter: grayscale(0%);
    }
    .home .container{
        transform: none;
    }
}


.social
{
  display: flex;
  bottom: 60px;
}
.social a{
  margin: 10px;
}
.social i
{
  padding-right: 30px;
  font-size: 25px;
  color: #3f3cdd;
}
.social i:hover
{
  color: #221e1e;
}
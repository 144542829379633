@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
:root{
  --sidebar-flex:1.5;
  --condiv-flex:6;
  --fontsize-p:1.2rem;
  --fontsize-nav:1.6rem;
  --typing-font:2rem;
}
*
{
  margin:0;
  padding: 0;
}
body{
  font-family: 'Raleway';
  overflow-x: hidden;
}

.App
{
  display: flex;
  width: 100vw;
  min-height: 100vh;
}

/* Main Body */
.condiv
{
  padding: 20px ;
  background-color: #f4f5f9;
  flex:var(--condiv-flex);
}

/* HyperLink */
a
{
    text-decoration: none;
    color: white;
}

a:hover{
    text-decoration: none;
    color: white;
}

.active
{
  font-weight: bolder;
  text-shadow: 2px 2px 4px #000000;
}

/* Font Size */
.subtopic
{
  margin:10px 10px 30px 10px !important;
}

/* Creation of Hover WileCard */
.widecard a {
    text-decoration: none;
    color: chocolate;
    margin: 10px;
}

.widecard
{
    border:0px solid #9a9b9c;
    display: flex;
    margin:30px 0px 30px 0px;
}

.widecard .certify h5{
    font-style: "Lucida Console", Courier, monospace;
}

.widecard:hover
{
    color: #1b1d20;
    background-color: #ffffff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.widecard a:hover {
    text-decoration: underline;
    font-size: large;
    margin-bottom: 5px;
    text-shadow: 1px 1px 2px white,  0 0 5px chocolate;
}


@media only screen and (max-width: 600px) {
    .App
    {
      flex-direction: column;
    }
    .widecard .secondtextWebSite{
      display: none;
    }
  }

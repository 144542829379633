@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Josefin+Slab:100);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --sidebar-flex:1.5;
  --condiv-flex:6;
  --fontsize-p:1.2rem;
  --fontsize-nav:1.6rem;
  --typing-font:2rem;
}
*
{
  margin:0;
  padding: 0;
}
body{
  font-family: 'Raleway';
  overflow-x: hidden;
}

.App
{
  display: flex;
  width: 100vw;
  min-height: 100vh;
}

/* Main Body */
.condiv
{
  padding: 20px ;
  background-color: #f4f5f9;
  flex:6;
  flex:var(--condiv-flex);
}

/* HyperLink */
a
{
    text-decoration: none;
    color: white;
}

a:hover{
    text-decoration: none;
    color: white;
}

.active
{
  font-weight: bolder;
  text-shadow: 2px 2px 4px #000000;
}

/* Font Size */
.subtopic
{
  margin:10px 10px 30px 10px !important;
}

/* Creation of Hover WileCard */
.widecard a {
    text-decoration: none;
    color: chocolate;
    margin: 10px;
}

.widecard
{
    border:0px solid #9a9b9c;
    display: flex;
    margin:30px 0px 30px 0px;
}

.widecard .certify h5{
    font-style: "Lucida Console", Courier, monospace;
}

.widecard:hover
{
    color: #1b1d20;
    background-color: #ffffff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.widecard a:hover {
    text-decoration: underline;
    font-size: large;
    margin-bottom: 5px;
    text-shadow: 1px 1px 2px white,  0 0 5px chocolate;
}


@media only screen and (max-width: 600px) {
    .App
    {
      flex-direction: column;
    }
    .widecard .secondtextWebSite{
      display: none;
    }
  }


/* SideBar */
.sideNav
{
    padding: 7px 45px 0px 19px;;
    flex:var(--sidebar-flex);
    background: linear-gradient(180deg, rgb(103 105 238) 50%, rgba(132,124,252,1) 80%);
}

nav ul
{
    font-size:var(--fontsize-nav) ;
    text-align: center;
    list-style-type: none;
    text-decoration: none;
    color: white !important;
}

nav ul li
{
    margin:40px;
}

nav a:hover{
    font-weight: bolder;
}

.mobileNav{
    display: none;
}

.deskNav{
    position: fixed;
}

@media only screen and (max-width: 600px) {
    .deskNav{
      display: none;
    }
    .mobileNav{
        display: block;
    }
    .mobileNav .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgb(103 105 238) 50%, rgba(132,124,252,1) 80%);
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 30px;
      }
      
    .mobileNav .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 18px;
        color:white;
        display: block;
        transition: 0.3s;
      }
      
    .mobileNav .sidenav a:hover {
        color: #f1f1f1;
      }
      
    .mobileNav .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
      }

    .mobileNav span{
        font-size: 20px;
        cursor: pointer;
        color:wheat;
    }

    .mobileNav li{
        list-style-type: none;
    }
}


.social
{
  display: flex;
  bottom: 60px;
}
.social a{
  margin: 10px;
}
.social i
{
  padding-right: 30px;
  font-size: 25px;
  color: #3f3cdd;
}
.social i:hover
{
  color: #221e1e;
}

.home {
    margin: 0;
    min-height: 100vh;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .typingeffect {
    font-size: var(--typing-font);
    margin-bottom: 15px;
    margin-top: 20px;
    font-family: "Courier New", Courier, monospace;
  }

.home .container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

.smallPhoto{
    border-radius: 20%;
}

@media only screen and (max-width: 600px) {
    .typingeffect {
        font-size: 18px;
        margin-bottom: 15px;
        margin-top: 20px;
        font-family: "Courier New", Courier, monospace;
    }
    .home .large{
        display: none;
    }
    .home .smallPhoto{
        display:block;
        border-radius: 35%;
        width: 200px;
        margin: 30px;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
    }
    .home .container{
        transform: none;
    }
}

p{
    font-size: var(--fontsize-p);
}

h1,h2,h3,h4,h5{
    margin: 10px;
}

h3{
    font-weight: bold;
}

h4{
    font-size: large;
}
.skills ul li
{
  list-style-type:none;
  margin:30px;
}

.skill {
  display: flex;
  flex-wrap: wrap;
}
p{
    font-size: var(--fontsize-p);
}

h1,h2,h3,h4,h5{
    margin: 10px;
}

h3{
    font-weight: bold;
}

h4{
    font-size: large;
}

.slider {
    overflow-x: scroll;
}

.holder {
    width: 1102%;
}

.slider-wrap{
    width:920px;
    height:600px;
    margin:20px auto;
}

.slide {
    float: left;
    width: 920px;
    height: 600px;
    padding: 10px;
    position: relative;
}

.temp {
    position: absolute;
    color: white;
    font-size: 34px;
    bottom: 15px;
    left: 15px;
    font-family: 'Josefin Slab', serif;
    font-weight: 600;
}

#cert1 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/algorithms.PNG);
}
#cert2 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/botGoogle.PNG);
}
#cert3 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/botPython.PNG);
}
#cert4 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/dataScience.PNG);
}
#cert5 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/dataStructures.PNG);
}
#cert6 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/deepLearning.PNG);
}
#cert7 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/infosys1.PNG);
}
#cert8 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/infosys2.PNG);
}
#cert9 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/java.PNG);
}
#cert10 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/mathematics.PNG);
}
#cert11 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/python.PNG);
}
#cert12 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/FrontEndDeveloper.PNG);
}

.slide:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(transparent, black);
}

@media only screen and (max-width: 600px) {
    .slider-wrap{
        display: none;
    }
}


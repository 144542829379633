@import url(https://fonts.googleapis.com/css?family=Josefin+Slab:100);

p{
    font-size: var(--fontsize-p);
}

h1,h2,h3,h4,h5{
    margin: 10px;
}

h3{
    font-weight: bold;
}

h4{
    font-size: large;
}

.slider {
    overflow-x: scroll;
}

.holder {
    width: 1102%;
}

.slider-wrap{
    width:920px;
    height:600px;
    margin:20px auto;
}

.slide {
    float: left;
    width: 920px;
    height: 600px;
    padding: 10px;
    position: relative;
}

.temp {
    position: absolute;
    color: white;
    font-size: 34px;
    bottom: 15px;
    left: 15px;
    font-family: 'Josefin Slab', serif;
    font-weight: 600;
}

#cert1 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/algorithms.PNG);
}
#cert2 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/botGoogle.PNG);
}
#cert3 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/botPython.PNG);
}
#cert4 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/dataScience.PNG);
}
#cert5 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/dataStructures.PNG);
}
#cert6 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/deepLearning.PNG);
}
#cert7 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/infosys1.PNG);
}
#cert8 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/infosys2.PNG);
}
#cert9 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/java.PNG);
}
#cert10 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/mathematics.PNG);
}
#cert11 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/python.PNG);
}
#cert12 {
    background-image: url(https://youtubesampledemoapp.s3.ap-south-1.amazonaws.com/certfications/FrontEndDeveloper.PNG);
}

.slide:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(transparent, black);
}

@media only screen and (max-width: 600px) {
    .slider-wrap{
        display: none;
    }
}
